<template>
  <div>
    {{scaleConfig.showName}}
  </div>
</template>

<script>

import {dynamicScaleComponent} from "@/mixins/dynamicScaleComponent";

export default {
  name: "library-view",
  mixins: [dynamicScaleComponent],
  components: {},

  props: {},

  data() {
    return {
      scale: {},
    }
  },
  mounted() {

  },
  computed: {},
  methods: {}
}
</script>

<style>
</style>